<template>
    <div class="plan">
        <v-container :fluid="plans.length > 2">
            <v-row class="main__plans plan darkGrayishBlue--text justify-center">
                <v-col
                    cols="12"
                    :lg="12 / plans.length"
                    :class="['pb-16', {'plan__item--hero': ((plans.length == 3) && (i == 1))}]"
                    v-for="(plan,i) in plans"
                    :key="`plan-${i}`"
                >
                    <div :class="`plan__item plan__item--${i+1}`">
                        <h4 class="plan__title">{{ plan.title }}</h4>
                        <p v-if="plan.price" class="plan__price">
                            <span class="plan__price-prefix">$</span>
                            <span class="plan__price-value">{{ plan.price }}</span>
                            <span class="plan__price-period">/monthly</span>
                        </p>
                        <p class="plan__subtitle">{{ plan.subtitle }}</p>
                        <div class="plan__description"
                            v-html="plan.description">
                        </div>
                        <v-btn
                            :to="plan.demoRout"
                            tile
                            block
                            x-large
                            class="plan__btn plan__btn--demo white--text darkGrayishBlue mb-8"
                        >
                            See Demo {{ demoTitle }} Profile
                        </v-btn>
                        <v-btn
                            :disabled="$auth.user()"
                            :to="plan.signUpRout"
                            tile block x-large
                            class="plan__btn plan__btn--signup white--text"
                            :class="(i == 1) ? 'strongRed' : 'darkGrayishBlue'"
                        >
                            Sign Up Now!
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import plans from "./plans";

export default {
    name: "PlansBlock",
    props: {
        type: {
            type: String,
            default: 'artist',
        },
    },
    data: function () {
        return {
            plans: plans[this.type],
        }
    },
    computed: {
        demoTitle() {
            return this.type.toLowerCase().replace(/^.|\s\S/g, (a) => a.toUpperCase())
        }
    }
}
</script>
