<template>
    <front-layout>
        <v-container fluid class="page__top-line">
            <v-row>
                <v-col
                    cols="12"
                    md="7"
                    lg="8"
                    class="pl-8 pl-lg-16 pb-9 artistPrimary--bg white--text "
                >
                    <h1 class="main__head-1 mb-4">Artists Connection</h1>
                    <div class="main__text-intro pl-sm-8 mb-8">
                        <p>Ready to book more gigs?</p>
                        <p>When you’re ready to get on stage, GrubTunes has the tools you need.</p>
                        <p>Book more gigs and get your music in front of more local audiences. It’s seriously the easiest way to post music and get on the list for local shows. People don’t come to GrubTunes looking for any and everything: they come looking for local musicians and artists. Like you.</p>
                        <p>Become the artist or band to see. GrubTunes puts you in the spotlight!</p>
                    </div>
                    <div v-if="!artistsLoading" class="pl-sm-8">
                        <h3 class="main__head-3 mb-4">Featured Artists</h3>
                        <v-container fluid class="pa-0">
                            <v-row>
                                <v-col
                                    sm="6"
                                    md="12"
                                    lg="6"
                                    class="pr-lg-8"
                                    v-for="(artist,i) in featuredArtists"
                                    :key="`artist-${i}`"
                                    >
                                    <v-sheet
                                        white
                                        rounded="lg"
                                        class="main__featured-card darkGrayishBlue--text"
                                        >
                                    <router-link :to="{name: 'artist.public', params: {slug: artist.slug}}">
                                        <v-container fluid>
                                            <v-row align="center">
                                                <v-col cols="4">
                                                    <v-img
                                                        :src="artist.imgUrl"
                                                        height="100%"
                                                        min-height="90px"
                                                    ></v-img>
                                                </v-col>
                                                <v-col cols="8">
                                                    <h4 class="mb-2">{{ artist.name }}</h4>
                                                    <p class="mb-0">{{ artist.address }}</p>
                                                </v-col>
                                                <v-col cols="12" class="pt-0">
                                                    {{ artist.text }}
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </router-link>
                                    </v-sheet>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    md="5"
                    lg="4"
                    class="main__advantages veryDarkPink white--text pa-10"
                    >
                    <div class="mx-auto" style="width: fit-content;">
                        <h2 class="main__head-2 mb-10">Sign Up Now for Free!</h2>
                        <ul class="mb-16">
                            <li
                                v-for="(advantage,i) in advantages"
                                :key="`advantage-${i}`"
                            >
                                {{ advantage }}
                            </li>
                        </ul>
                        <v-btn
                            :disabled="$auth.user()"
                            :to="{name: 'sign-up', query: { role: 'artist' }, hash: '#signupForm'}"
                            block
                            x-large
                            class="white veryDarkPink--text rounded-lg py-8"
                        >
                            Sign Up Now!
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <plans-block type="artist"/>
    </front-layout>
</template>

<script>
import FrontLayout from "@/layouts/FrontLayout";
import Artist from "@/models/Artist";
import PlansBlock from "@/components/plans/PlansBlock";

export default {
    name: "ArtistsLanding",
    components: {FrontLayout, PlansBlock},
    data: function () {
        return {
            artistsLoading: false,
            artists: [],
            advantages: [
                'Be found by new fans',
                'FREE communication',
                'Get booked easily by venues in your area',
                'Become a featured artist',
                'Connect with other artists',
                'Get your music to more people',
                'Book MORE local gigs every weekday or weekend',
            ],
        }
    },
    computed: {
        featuredArtists() {
            if (!this.artists.length) {
                return []
            }

            return [
                this.getArtistData(0),
                this.getArtistData(1),
            ]
        }
    },
    async mounted() {
        this.artistsLoading = true
        await this.fetchArtists()
        this.artistsLoading = false
    },
    methods: {
        async fetchArtists() {
            this.artists = await Artist.custom('artists/public').orderBy('-id').limit(2).get();
        },
        getArtistData(i) {
            return {
                name: this.artists[i].name,
                slug: this.artists[i].slug,
                address: this.artists[i].city.display_name,
                text: this.artists[i].bio,
                imgUrl: this.artists[i].avatar_url,
            }
        }
    },
}
</script>

<style scoped>
</style>
