export default {
    artist: [
        {
            title: 'FREE',
            description: `
            <p>Set up your profile, upload audio files, link social accounts and showcase your talent all in one place.</p>
            <p>What you get:</p>
            <ul>
              <li>Venue and open booking search</li>
              <li>Request to book</li>
              <li>New music alerts</li>
              <li>Advertise your pay scale</li>
              <li>Artist “classifieds” to find equipment of bandmates</li>
              <li>Visible account analytics, including page views, link clicks, music plays and “Book Me.”</li>
              <li>Name</li>
              <li>Stage name</li>
              <li>Music genre</li>
              <li>One song demo upload</li>
              <li>100 word bio</li>
            </ul>
            `,
            demoRout: '',
            signUpRout: {name: 'sign-up', query: { role: 'artist', price_plan: 'free' }},
        },
        {
            title: 'ROCK',
            price: 14,
            description: `
            <p>Get PROMOTED: users can search and find where you’re playing in location-based event listings.</p>
            <p>What you get:</p>
            <ul>
              <li>Venue and open booking search</li>
              <li>Request to book</li>
              <li>New music alerts</li>
              <li>Advertise your pay scale</li>
              <li>Artist “classifieds” to find equipment of bandmates</li>
              <li>Visible account analytics, including page views, link clicks, music plays and “Book Me.”</li>
              <li>Name</li>
              <li>Stage name</li>
              <li>Profile photo</li>
              <li>Music genre</li>
              <li>Multiple song/video uploads</li>
              <li>Limitless bio</li>
              <li>Link to booking calendar</li>
              <li>Link to social media/music accounts</li>
              <li>Link to artist webpage</li>
              <li>Link to merch store on your artist site</li>
              <li>Calendar for availability</li>
              <li>BOOK ME button</li>
            </ul>
            `,
            demoRout: '',
            signUpRout: {name: 'sign-up', query: { role: 'artist', price_plan: 'pro' }},
        },
    ],
    venue: [
        {
            title: 'FREE',
            subtitle: 'FOREVER FREE',
            description: `
            <p>Set up an account, browse local talent and show up in search.</p>
            <p>What you get:</p>
            <ul>
                <li>Search for, view and book artists</li>
                <li>Search to fill last-minute cancellations</li>
                <li>Rate artists</li>
                <li>Access to analytics including number of times people view your profile and menu</li>
                <li>Business Name</li>
                <li>Business Address (with mapping/directions link)</li>
                <li>Business Phone</li>
            </ul>
            `,            demoRout: '',
            signUpRout: {name: 'sign-up', query: { role: 'venue', price_plan: 'free' }},
        },
        {
            title: 'BASIC',
            subtitle: 'MOST POPULAR!',
            price: 9,
            description: `
            <p>Get PROMOTED: users can search and find your restaurant. Artists can find and request to book with you.</p>
            <p>What you get:</p>
            <ul>
                <li>Search for, view and book artists</li>
                <li>Search to fill last-minute cancellations</li>
                <li>Rate artists</li>
                <li>Access to analytics including number of times people view your profile and menu</li>
                <li>Business Name</li>
                <li>Business Address (with mapping/directions link)</li>
                <li>Business Phone</li>
                <li>Link to your website</li>
                <li>Menu link (black and white)</li>
                <li>One photo or logo</li>
                <li>Calendar link</li>
            </ul>
            `,
            demoRout: '',
            signUpRout: {name: 'sign-up', query: { role: 'venue', price_plan: 'basic' }},
        },
        {
            title: 'PRO',
            price: 14,
            description: `
            <p>Get FEATURED: max out visibility and ensure you are everyone’s first choice to book a show or buy food and drinks.</p>
            <p>What you get:</p>
            <ul>
                <li>Search for, view and book artists</li>
                <li>Search to fill last-minute cancellations</li>
                <li>Rate artists</li>
                <li>Access to analytics including number of times people view your profile and menu</li>
                <li>Business Name</li>
                <li>Business Address (with mapping/directions link)</li>
                <li>Business Phone</li>
                <li>Link to your website</li>
                <li>Menu link (full color)</li>
                <li>Logo and multiple photos</li>
                <li>Calendar link</li>
            </ul>
            `,
            demoRout: '',
            signUpRout: {name: 'sign-up', query: { role: 'venue', price_plan: 'pro' }},
        },
    ],
}

